/* Base styles */
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  text-align: center;
  color: white;
  font-family: 'VT323', monospace;
  margin: 0;
  overflow: hidden;
  background-color: #000; /* Adjust this as necessary */
}

.Boner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.Boner h1 {
  font-size: 20vw; /* Adjust this as necessary */
  white-space: nowrap;
  margin: 0 auto;
}

/* Center and style icons */
.Icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.Icons a {
  color: white; /* Make the icons white */
  text-decoration: none;
}

/* Responsive font sizes for Boner h1 */
@media (max-width: 320px) {
  .Boner h1 {
    font-size: 15vw; /* Slightly larger but still for small screens */
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .Boner h1 {
    font-size: 18vw;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .Boner h1 {
    font-size: 20vw;
  }
}
